<template>
    <svg :width="size + 'px'" :fill="color" viewBox="0 0 101.005 97.916">
        <g>
            <path id="Devoluciones_icon" data-name="Devoluciones icon" d="M9373.5,1550.852a2.69,2.69,0,0,1-.435,0h0a3.22,3.22,0,0,1-3.075-3.358c-.088-1.922,1.492-3.278,3.828-3.3,2.166-.021,4.334-.012,6.5,0,3.45.014,6.9.028,10.347-.073a13.289,13.289,0,0,0,3.776-25.807,12.706,12.706,0,0,0-3.864-.76c-5.028-.087-10.065-.035-15.1-.035h-1.422c1.28,1.136,3.51,2.841,4.686,4.067a3.5,3.5,0,0,1,.435.435,3.315,3.315,0,0,1-5.089,4.251q-4.875-4.751-9.634-9.631a2.953,2.953,0,0,1-.435-.456l0,0a3.253,3.253,0,0,1,.475-4.582c3.179-3.238,6.381-6.464,9.643-9.622.063-.068.133-.135.2-.2a3.319,3.319,0,0,1,4.433,4.941c-1.2,1.2-4.686,4.109-4.686,4.109h1.306c4.832,0,9.669-.042,14.5,0,.46,0,.928,0,1.389.035a19.935,19.935,0,0,1,18.686,21.1,19.674,19.674,0,0,1-17.4,18.63c-3.178.256-6.368.32-9.564.32C9379.834,1550.915,9376.664,1550.852,9373.5,1550.852Zm-56.59-14.733a6.913,6.913,0,0,1-6.913-6.913V1457a2.98,2.98,0,0,1,2.98-2.978H9340.9V1480.5l10.139-6.847,10.145,6.847V1454h27.941a2.98,2.98,0,0,1,2.98,2.98v49.266c-.734-.064-1.436-.227-2.175-.227h-5.473a7.915,7.915,0,0,0-1.955-7.354,7.709,7.709,0,0,0-5.812-2.61,8.5,8.5,0,0,0-5.886,2.475c-2.068,2.012-4.093,4.074-6.131,6.124h-27.77c-.423,0-.768.5-.768,1.11v5.793c0,.612.333,1.105.768,1.105h21.905a7.446,7.446,0,0,0,.772,5.111h-6.927c-.354,0-.641.5-.641,1.105v5.793c0,.612.286,1.108.641,1.108H9366.9c1.272,1.271,2.579,2.6,3.883,3.88a8.463,8.463,0,0,0,5.886,2.475,8,8,0,0,0,7.718-9.922q3.042,0,6.089.042a8.226,8.226,0,0,1,1.6.345v6.587a6.913,6.913,0,0,1-6.9,6.932Z" 
            transform="translate(-9309.498 -1453.499)" fill="#a5b2b7" stroke="rgba(0,0,0,0)" stroke-width="1"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'iconDevolutions',
    components: {},
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
</style>

